@media (min-width: 300px) and (max-width: 639px){
.xs\:mx-6 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
}
}

@media (min-width: 300px) and (max-width: 639px){
.xs\:w-full {
    width: 82%;
}
}

@media (min-width: 300px) and (max-width: 639px){
.xs\:mx-4 {
    margin-left: 2rem;
    margin-right: 1rem;
}
}