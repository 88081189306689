.product_txt {
  padding-top: 0px !important;
}

.product_detail_text {
  padding-top: 15px;
}


.Dropdown-control {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  background-color: black;
  color: white;
  padding:10px 30px;
  font-size: 16px;
  border: none;
  font-weight: 600;
  border-radius: 8px;
  text-align: center;
}

.filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  background-color: black;
  color: white;
  padding:12px 40px;
  font-size: 16px;
  border: none;
  font-weight: 600;
  border-radius: 8px;
  text-align: center;
}

.Dropdown-arrow {
  display: none;
}

.filter-cover {
  width: 95%;
}

.search {
  width: 90%;
  margin-left: 5%;
}

.search {
  display: none;
}

@media (max-width: 700px) {
  .none {
    display: none;
  }
  .search {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 20px;
  }
  .search-input {
    width: 100%;
  }


}

.filter-btn {
  display: none;
}

@media (max-width: 900px) {
  .filter-btn {
    display:flex;
  }

  .filter-cover{
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 1025px) {
  .navi {
    display: none;
  }
}

@media (max-width: 1000px) {
  .col3 {
    display: none;
  }
}

@media (min-width: 300px) and (max-width: 639px) {
  .xs\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}