.login {
  margin-top:40px;
  font-size: 40px;
}
.App {
    margin-top: 10%;
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #09d3ac;
  }
  
  .otpContainer {
    margin: 5% auto;
  }
  
  .otpInput {
    width: 3rem !important;
    height: 3rem;
    margin: 0 1rem;
    font-size: 2rem;
    text-align: center;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  