.questions {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.ques-container {
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
}

button {
    outline: none;
}

.button:active img {
    border: 2px solid black;
}

.questions button img {
    background-color: #e3e3e3;
}

.based {
    color: black;
    font-size: 40px;
    font-weight: 500;
    text-align: center;
}

.back {
    width: 55px !important;
    height: 55px !important
 }

@media (min-width: 300px) and (max-width: 639px) {
    .xs\:w-44 {
        width: 9rem;
        height: 12rem;
    }

    
}

@media (min-width:800px) {
    .options button {
        outline: none;
        width: 250px;
    }
}

@media (max-width: 600px) {
    .back {
        width: 35px !important;
        height: 35px !important;
     }

     .skip, .next{
        border: 2px solid black !important;
        text-align: center !important;
     }
}
