.loginInnerMainContainer {
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
}

.loginInnerMainContainer select {
    height: 55px;
}

@media screen and (max-width: 1279px) {
    .loginInnerMainContainer select {
        width: 18.7rem;
    }
}

@media screen and (max-width: 1024px) {
    .loginImageContainer {
        width: 70vw;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10vh 15vw 0px 15vw;
    }
    .loginImageContainer img {
        width: 85%;
        height: auto;
        max-width: 300px;
    }
    .loginInnerMainContainer {
        margin-bottom: 10vh !important;
    }
}

@media (min-width: 300px) and (max-width: 639px) {
    .xs\:w-full {
        width: 65% !important;
    }
    .login {
        font-size: 2rem !important;
    }
    .loginInnerMainContainer input, .loginInnerMainContainer select {
        width: 60vw;
        height: 5vh;
        padding: 10px 10px !important;
    }

    .suggestion-div {
        width: 60vw;
    }
    .loginInnerMainContainer input::placeholder {
        font-size: 14px;
    }
    .loginInnerMainContainer button {
        width: 30vw;
        height: 40px;
        font-size: 1rem;
        padding: 10px;
    }
}

.login {
    margin-bottom: 20px;
    margin-top: 40px;
    font-size: 40px;
}

@media (min-width: 300px) and (max-width: 639px) {
    .xs\:w-screen {
        width: 100vw !important;
    }
}

@media (min-width: 300px) and (max-width: 639px) {
    .xs\:w-screen {
        width: 100vw !important;
    }
}

@media (min-width: 300px) and (max-width: 639px) {
    .xs\:h-5\/6 {
        height: 100.333333%;
    }
}

@media (min-width: 300px) and (max-width: 639px) {
    .xs\:py-8 {
        padding-top: 6rem;
        padding-bottom: 3rem;
        padding-left: 2rem;
        padding-right: 2rem;
        /* padding: 4rem; */
        margin-top: -27rem;
    }
}

@media (min-width: 300px) and (max-width: 639px) {
    .xs\:p-8 {
        padding: 2rem;
        padding-left: 3rem;
        padding-right: 3rem;
        margin-top: -25rem;
    }
}

@media (min-width: 300px) and (max-width: 639px) {
    .xs\:w-5\/6 {
        width: 83.333333%;
        height: 110%;
    }
}

.py-3 {
    padding-top: 0.50rem;
    padding-bottom: 0.5rem;
}

.px-3 {
    padding-left: 0.75rem;
    padding-right: 8.75rem;
}

@media (min-width: 300px) and (max-width: 639px) {
    .xs\:w-screen {
        width: 100vw !important;
    }
}