.dealer {
  float: left;
  padding-left: 9px;
  width: 99%;
  color: gray;
}

input[type=text]:focus {
  outline: 2px solid;
}

input {
  border: 1px solid #999;
  padding: 0.5rem;
  max-width: 300px;
}

.suggestion-div,
.select {
  width: 300px;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
}

.suggestions .list {
  padding: 0.5rem;
}

.suggestions .list:hover {
  cursor: pointer;
  font-weight: 700;
}

.suggestions .list:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

/* @media (min-width: 300px) and (max-width: 639px) {
    .d-name {
        margin-left: 21px ;
        width: 82%;
    }
    .d-code {
        margin-left: 21px;
        width: 82%;
    }
    .d-city {
        margin-left: 21px;
        width: 82%;
    }
} */