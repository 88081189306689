.Cart {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  width: 50%;
}

.Cart div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customer {
  color: black;
  font-size: 35px;
  font-weight: 500;
}

.cart {
  color: black;
  font-size: 35px;
  font-weight: 600;
  margin-left: 10px;
}

.txt1 {
  color: black;
  font-size: 21px;
  font-weight: 700;
}

.txt {
  color: black;
  font-size: 15px;
  font-weight: 600;
}

.name {
  color: black;
  font-size: 25px;
  font-weight: 600;
}

.count {
  margin-left: 5px;
  margin-right: 5px;
}


.QuantityContainer {
  display: flex;
  justify-content: center; 
  align-items: center;
  border-radius: 5px; 
  border-color: black; 
  border-width: 1px; 
  padding: 10px;
  width: 100px; 
  padding-left: 20px; 
  align-self: center; 
}
.Price {
  display: flex;
  justify-content: flex-end;
}

.Discountbox {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.TotalDiscount {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.discountitem{
  flex-grow: 1;
  flex-shrink:0;
  flex-basis:50%;
}
.discountitems{
  flex-grow: 1;
  flex-shrink:0;
  flex-basis:50%;
}
.discountitem1{
  display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    /* flex-basis: 60%; */
    justify-content: flex-end;
}
.Customer{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.Discountbox .discountitem{max-width: 150px;margin-left: 20px;text-align: end;}
.Discountbox .discountitems{max-width: 150px;margin-left: 20px;}


.delear{
  width: 500px;
}

@media (min-width: 300px) and (max-width: 639px) {
  .name {
    width: 240px;
  }
  .remarks {
    width: 240px;
  }
}

@media (min-width: 300px) and (max-width: 639px) {
  .xs\:px-8 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .discountitem1{    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 60%;
    justify-content: flex-end;}
    
    .Discountbox .discountitem{max-width: 300px;margin-left: 0px;}
    .Discountbox .discountitems{max-width: 300px;margin-left: 0px;}
}


@media screen and (max-width: 767px) {
  .mainCartContainer {
    grid-template-columns: 1.5fr 2fr 0.5fr;
  }

  .mobile-view-cart-text p{
    padding: 0px !important;
    margin: 0px !important;
  }

  .QuantityContainer {
    padding: 5px;
    width: 90px;
    justify-content: center !important;
  }
  .cartMobileFont {
    font-size: 14px;
    color: #000;
  }

  .Cart {
    margin-bottom: -5%;
  }
}

@media (min-width: 1024px) {
  .share-cart img{
    width:60%
  }  
}