@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap'); */

@font-face {
    font-family: HelveticaNeue;
    src:
        url('./Helvetica neue/HelveticaNeueLTPro-Roman.otf') format('opentype'),
        url('./Helvetica neue/HelveticaNeueLTPro-Lt.otf') format('opentype'),
        url('./Helvetica neue/HelveticaNeueLTPro-Th.otf') format('opentype');
}

* {
    font-family: HelveticaNeue;
    --swiper-theme-color: #000;
}

/* .public-DraftStyleDefault-ltr {
    direction: ltr;
    text-align: left;
    border: 2px solid black !important;
} */
.rdw-editor-main {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    border: 1px solid black !important;
}
.rdw-block-wrapper {
   
    display: none !important;
}
.rdw-fontsize-wrapper {
    display: none !important;
}
.rdw-fontfamily-wrapper {
    display: none !important;
}
.rdw-editor-toolbar {
    padding: 6px 5px 0;
    border-radius: 2px;
    border: 1px solid #F1F1F1;
    display: flex;
    justify-content: flex-start;
    background: white;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
    -webkit-user-select: none;
    user-select: none;
    background-color: lightgray !important;
}

select {
    width: 100%;
    color: #9ca3af;
}

button:disabled {
    background-color: #9ca3af;
    cursor: default;
}

.shareCart-Name {
    font-size: 18px;
}

input:focus {
    outline: none !important;
}

.table {
    width: 100%;
    text-align: left;
}

.table td {
    padding-bottom: 10px;
}

.react-datepicker-wrapper {
    display: block !important;
}

.img-btn img {
    max-width: 180px;
}

.btn-1 {
    padding: 10px 12px;
}

.btn-1.file {
    padding: 15px;
}

.rdw-emoji-wrapper{
    display: none !important;
}
.rdw-image-wrapper{
    display: none !important;
}
.rdw-text-align-wrapper{
    display: none !important;
}
.rdw-link-wrapper{
    display: none !important;
}
.rdw-embedded-wrapper{
    display: none !important;
}
.rdw-remove-wrapper{
    display: none !important;
}
/* .rdw-option-wrapper{
    display:none !important; ;
} */
.rdw-history-wrapper{
    display:none !important; 
}
.rdw-option-active{
    display:none !important; 
}
.rdw-option-wrapper:nth-child(4){
    display:none !important; 
}
.rdw-option-wrapper:nth-child(5){
    display:none !important; 
}
.rdw-option-wrapper:nth-child(6){
    display:none !important; 
}
.rdw-option-wrapper:nth-child(7){
    display:none !important; 
}
.rdw-colorpicker-wrapper{
    display:none !important; 
}

.rdw-editor-main {
    height: 100px !important;
    overflow: auto;
    box-sizing: border-box;
}

.public-DraftStyleDefault-block {
    margin: 0em !important;
}