.navi-n {
    font-size: 20px;
    margin-bottom: 15px;
    letter-spacing: 0.2px;
    color: #000;
}

.navi-active {
    font-weight: 600;
}

.navParent,
.navParentAdmin {
    height: auto;
    padding-left: 3vw;
    padding-right: 1vw;
}

@media screen and (min-width:1100px) {
    .navParent {
        width: 13vw;
    }
}

.handleMobileView,
.handleMobileViewOfSearch,
.handleMobileViewOfGrid,
.handleMobileViewOfGridWithFlex {
    display: none !important;
}

.sliderImage,
.mySwiper {
    width: 90% !important;
    height: auto;
}

.mainContainer {
    /* height: calc(100vh - 145px); */
    height: auto;
}

.dashBoardMainListing {
    /* height: calc(100vh - 160px); */
    height: auto;
    border-width: 4px;
    border-color: #000;
    border-radius: 5px;
    width: 87%;
    margin-right: 2%;
    padding: 0 5vw;
}

.dashBoardMainListingHeader {
    margin-top: 50px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashBoardMainListingHeader h1 {
    font-weight: 700;
    align-self: center;
    padding-bottom: 10px;
}

.dashBoardMainListingHeader .section_1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashBoardMainListingHeader input {
    border-width: 2px;
    border-color: #000;
    border-radius: 5px;
    padding: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-right: 10px;
}

.collateralMain {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: calc(100vh - 160px);
    border-width: 4px;
    border-color: #000;
    border-radius: 5px;
    width: 85%;
}

.collateralInnerListing {
    margin: 2vh 3vw !important;
}

.collateralInnerListingMain {
    width: 100% !important;
    height: 50vh !important;
}

.collateralInnerListingMain.wrapper {
    height: 300px !important;
}


@media screen and (max-width:1099px) {
    .navi-n {
        font-size: min(3.2vw, 20px);
    }

    .handleDesktopView {
        display: none !important;
    }

    .handleMobileView {
        display: initial !important;
    }

    .navParent {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 6vw;
    }

    .mainDirection {
        flex-direction: column;
    }

    .sliderImage {
        width: 60vw !important;
        height: 24vh !important;
    }

    .dashBoardMainListing {
        height: 50vh;
        width: 92.75vw;
        margin: 0 3.625vw;
    }

    .collateralMain {
        grid-template-columns: repeat(2, 1fr);
        margin: 0 6vw;
        width: 88vw !important;
    }
}

@media screen and (max-width:899px) {
    .handleDesktopViewOfSearch {
        display: none !important;
    }

    .handleMobileViewOfSearch {
        display: initial !important;
    }

    .dashBoardMainListingHeader {
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .dashBoardMainListingHeader .section_2 {
        display: grid;
        grid-template-rows: 1;
    }

    .dashBoardMainListingHeader input {
        margin-top: 10px;
        padding: 10px 15px;
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .collateralMain {
        display: flex;
    }

    .handleDesktopViewOfGrid {
        display: none !important;
    }

    .handleMobileViewOfGrid {
        display: initial !important;
    }

    .handleMobileViewOfGridWithFlex {
        display: flex !important;
    }

    .sliderImage,
    .mySwiper {
        width: 96% !important;
        height: 95% !important;
    }

    .dashBoardMainListing {
        height: calc(100vh - 100px);
    }

    .mainContainer {
        height: calc(100vh - 120px);
    }
}