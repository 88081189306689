h1 {
    padding-top: 15px;
}
p {
    font-size: 15px;
    font-weight: 500;
    color: gray;
    margin-top:10;
}

.filter-container {
    border: 2px solid black;
}

@media (min-width: 1001px) and (max-width: 3500px){
   .button{
       display: none;
   }  
}


@media (max-width: 900px) {
    .filter-container{
        border: none !important;
        width: 80%;
    }
}

@media (max-width: 400px) {
    .filter-container{
        border: none !important;
        width: 100%;
    }
}