
  
  .dash{
    color: #000;
    font-size: 18px;
    font-weight: 100;
  }
  
  .product{
  font-size: 25px;
  margin-top: 15px;
  }


.content{
    overflow: hidden;
}

.column-4 {
    grid-template-columns: repeat(4, 1fr) !important;
}

@media (min-width: 300px) and (max-width: 639px){
.xs\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
}

@media (min-width: 300px) and (max-width: 739px){
    .navi-c {
        display: none;
    }
    .col1 {
        display: none;
    }
}

@media (min-width: 300px) and (max-width: 639px){
.xs\:mx-8 {
    margin-left: 3.5rem;
    margin-right: 2rem;
}
}

@media (min-width: 300px) and (max-width: 639px){
.xs\:w-44 {
    width: 6rem;
    height: 4rem;
}
}

@media (min-width: 300px) and (max-width: 639px){
.xs\:py-4 {
    padding-top: 1rem;
    padding-bottom: 3rem;
}
}

@media (min-width: 300px) and (max-width: 639px){
.xs\:w-28 {
    width: 5rem;
}
}

