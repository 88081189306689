.adminMainContainer {
    height: calc(100vh - 150px) !important;
}

.adminHeaderH1 {
    font-size: 2.5rem;
    font-weight: 500;
}

/* .adminProductBtnAdd {} */
.slider{width: 200px;}

.adminProductsInnerMain {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr 2fr;
    padding: 20px 3vw;
}

.AdminBOQInnerContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.grid-image {
    max-width: 100px;
}