.col2{
    width:100%;
}

.box{
    margin-left: 10px;
}

.based {
    color: black;
    font-size: 38px;
    font-weight: 500;
}


@media (max-width: 900px){
    .col1{
        display: none;
    }

    .col2{
        width:100%
    }
    .box{
        margin-left: 0px;
    }
    
    
}

@media (max-width: 700px) {
    .based {
        font-size: 21px;
    }
}

/* @media only screen and (max-width: 1025px){
    .col2{
        display: none;
    }
    .navb{
        display: none;
    }
} */