.dash{
  color: #000;
  font-size: 15px;
}

.product{
font-size: 20px;
margin-top: 15px;
}

.swiper-button-next:after, .swiper-button-prev:after{
  display: none;
}

.swiper-container {
  width: 70%;
  height: auto;
}

@media only screen and (max-width: 730px){
  .carousel-mob{
    width:'screen'; 
  }
  .swiper-button-next {
    background-image: url(../../assets/Group\ 100.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 50% auto !important;
    background-position: center !important;
  }
  .swiper-button-prev {
    background-image: url(../../assets/Group\ 99.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 50% auto !important;
    background-position: center !important;
  }
}

@media (min-width: 1281px) {
  .swiper-container{
    width: 60%;
    height: auto;
  }
  .carousel-mob{
    display: none;
  }
}

@media (min-width: 1000px) and (max-width: 1280px)  {
  .swiper-container{
    width: 80%;
    height: auto;
    margin-top: 15px;
  }
  .carousel-mob{
    display: none;
  }
}

@media (min-width: 731px) and (max-width: 999px){
  .carousel-mob{
    display: none;
  }
  .carousel-home{
    margin-top: 25px;
  }

}

@media only screen and (max-width: 730px) {
  .carousel-home{
    display: none;
  }
  .carousel-mob{
    margin-top: 25px;
  }  
}

@media (min-width: 300px) and (max-width: 639px){
.xs\:w-screen {
    width: 92vw !important;
}

  .swiper-button-nex{
      width: 12px !important;
      height: 12px !important;
  }

  .swiper-button-next, .swiper-button-prev {
    display: none;
  }

  /* .xs\:my-2 {
    margin-top: 7.5rem;
    margin-bottom: 0.5rem;
}

.xs\:my-2 {
    margin-top: 11.5rem !important;
    margin-bottom: 0.5rem !important;
}

.xs\:my-2 {
    margin-top: 12.5rem;
    margin-bottom: 0.5rem;
} */

.xs\:py-16 {
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
}

.xs\:h-32 {
    height: 6rem;
}

.xs\:px-8 {
    padding-left: 1rem;
    padding-right: 1rem;
}

}
@media only screen and (max-width: 730px){
/* .carousel-mob {
    margin-top: -110px;
    margin-bottom: -69px;
} */
.carousel-mob {
    margin-top: 6px;
    margin-bottom: -166px;
}
}

@media only screen and (max-width: 1280px){
.swiper-slide img {
    display: block;
    width: 63%;
    height: 52%;
    object-fit: cover;
}
}


.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  left: 0px;
  right: auto;
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  right: 0px;
  left: auto;
}
.swiper-button-next {
  background-image: url(../../assets/Group\ 100.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
.swiper-button-prev {
  background-image: url(../../assets/Group\ 99.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next{
  color:black !important;
  padding: 27px  !important;
}


.swiper-button-prev{
  color:black !important;
  padding: 27px !important;
}

span .swiper-pagination-bullet swiper-pagination-bullet-active{
  background-color: black !important; 
}

/*

sorting code

border: 0;
    border-bottom: 3px solid;
    margin-left: .5rem;
    margin-right: .5rem;

*/

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 70%;
  height: 55%;
  object-fit: cover;
}

@media only screen and (max-width: 1535px){
  .swiper-slide img {
    display: block;
    width: 60%;
    height: 40%;
    object-fit: cover;
  }
}

@media only screen and (max-width: 1280px){
  .swiper-slide img {
    display: block;
    width: 65%;
    height: 50%;
    object-fit: cover;
  }
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
}
